import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from "axios";
import { deleteCookie, getCookie } from "cookies-next";
import { notify } from "../src/lib/utils/notify";

const token = getCookie("token");

type HttpMethod = "get" | "post" | "put" | "patch" | "delete";

interface HttpOptions {
  method?: HttpMethod;
  url: string;
  data?: Record<string, any>;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  timeout?: number;
  responseType?:
    | "json"
    | "arraybuffer"
    | "blob"
    | "document"
    | "text"
    | undefined;
  download?: boolean;
  searchParams?: Record<string, any>;
}

const baseConfig: AxiosRequestConfig = {
  baseURL: process.env.BASE_URL,
  timeout: 10000
};

if (token) {
  baseConfig.headers = {
    Authorization: `Bearer ${token}`
  };
}

export const https: AxiosInstance = axios.create(baseConfig);

https.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      deleteCookie("token");
      deleteCookie("account_type");
      if (typeof window !== "undefined") {
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

export const httpRequest = async <ResponseData>(
  options: HttpOptions,
  config: AxiosRequestConfig = {}
): Promise<ResponseData> => {
  const {
    method = "get",
    url,
    data,
    params,
    headers,
    timeout,
    responseType,
    download = false,
    searchParams
  } = options;

  let query = "";
  if (searchParams) {
    const filteredParams = Object.fromEntries(
      Object.entries(searchParams).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    if (Object.keys(filteredParams).length) {
      query = new URLSearchParams(filteredParams).toString();
    }
  }

  const isOnline = navigator.onLine; // check if the user is currently online
  if (!isOnline) {
    notify({
      type: "error",
      message:
        "You are currently offline. Please check your internet connection and try again."
    });
  }

  const httpConfig: AxiosRequestConfig = {
    method,
    url: query ? `${url}?${query}` : url,
    data,
    params,
    headers: { ...(headers || {}) },
    timeout,
    responseType: responseType,
    ...config
  };

  if (
    download &&
    httpConfig.headers &&
    httpConfig.headers["Content-Disposition"]
  ) {
    await downloadFile(
      url,
      httpConfig.headers["Content-Disposition"],
      httpConfig.responseType
    );
    return {} as ResponseData;
  }

  const response = await https(httpConfig);
  return response.data as ResponseData;
};

export const downloadFile = async (
  url: string,
  filename: string | number | boolean,
  responseType: ResponseType = "arraybuffer"
): Promise<void> => {
  try {
    const response = await https.get(url, {
      responseType
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    link.setAttribute("download", filename.toString());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    throw error;
  }
};

export const api = {
  get: <T>(url: string, params?: object) => https.get<T>(url, { params }),
  post: <T>(url: string, data: any) => https.post<T>(url, data),
  patch: <T>(url: string, data: any) => https.patch<T>(url, data),
  put: <T>(url: string, data: any) => https.put<T>(url, data),
  delete: <T>(url: string) => https.delete<T>(url)
};
