export const apiRoutes = {
  // **** AUTHENTICATION ****
  login: "/auth",
  verifyEmail: "/auth/verify-email/:token",
  resendToken: "/auth/resend-token",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",

  // **** ACCOUNT ****
  register: "/v1/users/register",
  getUserProfile: "/v1/users/profile",
  editUserProfile: "/v1/users/profile",
  getBusinessData: "/v1/business/:business_id",
  getBusinesses: "/v1/account/businesses",
  setActiveBusiness: "/v1/account/active-business/:business_id",
  updateBusinessProfile: "/v1/business/:business_id",
  setupAuthenticatorApp: "/v1/account/setup-2fa",
  verifyAuthenticatorApp: "/v1/account/verify-2fa",
  disableAuthenticator: "/v1/account/disable-2fa",
  sendAuthenticatorCode: "/v1/account/send-mail-2fa",
  verifyAuthenticatorCode: "/v1/account/verify-mail-2fa",
  changePassword: "/v1/account/change-password",

  // **** FILE UPLOAD ****
  getPresignedUrl: "/upload/presigned",

  //   **** COUNTRIES ****
  getCountries: "/countries",
  getCountry: "/countries/:id",
  updateCountry: "/countries/:id",

  //  **** DOCUMENTS ****
  postDocument: "/v1/documents",
  getDocumentTypes: "/v1/documents/types",
  getUserDocuments: "/v1/documents",

  //  **** MANAGE BUSINESS ****
  getBusinessSectors: "/v1/business/sectors",
  submitBusinessCompliance: "/v1/business",
  getBusinessAnalytics: "/v1/business/analytics/:business_id",
  getUserRoles: "/v1/business-admins/:business_id",
  addAdmin: "/v1/business-admins/:business_id",
  updateAdmin: "/v1/business-admins/:business_id/:admin_id",
  deleteAdmin: "/v1/business-admins/:business_id/:admin_id",
  getAdminInviteData: "/v1/business-invites/admin/:token",
  processAdminInvite: "/v1/business-admins/process-invite",

  //  **** MANAGE TALENTS ****
  addTalent: "/v1/business/:business_id/talents",
  uploadTalent: "/v1/business/:business_id/talents/upload",
  getTalent: "/v1/business/:business_id/talents/:talent_id",
  updateTalent: "/v1/business/:business_id/talents/:talent_id",
  deleteTalent: "/v1/business/:business_id/talents/:talent_id",
  getBusinessInvites: "/v1/business/:business_id/invites",
  updateInvite: "/v1/business/:business_id/invites/:invite_id",
  updateTalentStatus: "/v1/business/:business_id/talents/:talent_id/status",
  getTalentAnalytics: "/v1/talents/analytics",

  //  **** MANAGE TALENT ****
  getBusinessAdminInvites: "/v1/business-admins/invites",
  getTalentInvites: "/v1/talents/invites",
  processInvite: "/v1/talents/process-invite",
  getInviteData: "/v1/business-invites/talent/:token",
  submitTalentCompliance: "/v1/talents/compliance",

  //  **** MANAGE TEAMS ****
  createTeam: "/v1/business/:business_id/teams",
  updateTeam: "/v1/business/:business_id/teams/:teamId",
  deleteTeam: "/v1/business/:business_id/teams/:teamId",
  getBusinessTeams: "/v1/business/:business_id/teams",
  getTeam: "/v1/business/:business_id/teams/:teamId",

  //  **** MANAGE TRANSACTIONS ****
  getWalletBalance: "/v1/account/balance",
  getSupportedCoins: "/v1/transactions/crypto/coins",
  getTransactions: "/v1/transactions",
  getTransactionDetails: "/v1/transactions/:id",
  getTransactionRates: "/v1/transactions/crypto/rates",
  getWithdrawalAccounts: "/v1/transactions/withdrawal-accounts",
  deleteWithdrawalAccount: "/v1/transactions/withdrawal-accounts/:account_id",
  addCryptoWithdrawalAccount: "/v1/transactions/withdrawal-accounts/crypto",
  fundCryptoWallet: "/v1/transactions/crypto/fund",
  withdrawCrypto: "/v1/transactions/crypto/withdraw",
  addFiatWithdrawalAccount: "/v1/transactions/withdrawal-accounts/fiat",
  getFiatFundingRates: "/v1/transactions/fiat/funding-rate",
  getFiatDepositAccount: "/v1/transactions/fiat/deposit-accounts",
  fundFiatWallet: "/v1/transactions/fiat/fund",
  getPayoutCountries: "/v1/transactions/fiat/payout-countries",
  getPayoutCurrencies: "/v1/transactions/fiat/payout-currencies",
  getBanksInSpecifiedCountry: "/v1/transactions/fiat/banks",
  getFiatPayoutRate: "/v1/transactions/fiat/payout-rate",
  withdrawFiat: "/v1/transactions/fiat/withdraw",
  getTransactionsAnalytics: "/v1/transactions/analytics",
  getAvailableAssets: "v1/transactions/crypto/ledger-balance",

  // **** TRANSACTION WEBHOOKS ****
  postTransactionWebhook: "/v1/webhooks/lazerpay",

  // **** MANAGE PAYROLL ****
  getLatestPayroll: "/v1/payroll/business/:business_id/latest",
  getPayrollSettings: "/v1/payroll/settings/:business_id",
  updatePayrollSettings: "/v1/payroll/settings/:business_id",
  editPayItem: "/v1/payroll/pay-item/:pay_id",
  getPayrollHistory: "/v1/payroll/business/:business_id",
  getPayrollDetails: "/v1/payroll/:payroll_id",
  updatePayrollStatus: "/v1/payroll/:payroll_id",

  // **** MANAGE INVOICES ****
  sendInvoice: "/v1/invoice",
  getInvoices: "/v1/invoice",
  getReceivedInvoices: "/v1/invoice/received",
  getReceipts: "/v1/invoice/receipts",
  cancelInvoice: "/v1/invoice/cancel/:reference",
  payInvoice: "/v1/invoice/pay",
  getInvoice: "/v1/invoice/:reference",
  payInvoiceWithCrypto: "/v1/invoice/pay-crypto",
  downloadInvoice: "/v1/invoice/:reference/pdf",

  // **** EXTERMAL SERVICES ****
  getUserCountry: "https://ipapi.co/json/"
};
