import { useState } from "react";

export const useFormUtil = (formData: { [key: string]: any }) => {
  const DEFAULT_ERRORS: any = {};

  for (const key in formData) {
    DEFAULT_ERRORS[key] = [];
  }

  const [showLoader, setShowLoader] = useState(false);
  const [defaultErrors, setDefaultErrors] = useState(DEFAULT_ERRORS);

  return {
    showLoader,
    setShowLoader,
    defaultErrors,
    setDefaultErrors
  };
};
